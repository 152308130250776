import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      layout: 'AppLayoutHome'
    }
  },
  {
    path: '/1',
    name: 1,
    component: () => import('../views/BetterKnowPage.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      progress: 0

    }
  },
  {
    path: '/2',
    name: 2,
    component: () => import('../views/IdentifierPage.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      option: "identifiers",
      progress: 20
    }
  },
  {
    path: '/3',
    name: 3,
    component: () => import('../views/BannerPage.vue'),
      meta: {
        layout: 'AppLayoutDefault',
        option: "banner",
        progress: 40
      }
  },
  {
    path: '/4',
    name: 4,
    component: () => import('../views/SpotPage.vue'),
      meta: {
        layout: 'AppLayoutDefault',
        option: "spot",
        progress: 60
    }
  },
  {
    path: '/5',
    name: 5,
    component: () => import('../views/OverallPage.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      option: "overall",
      progress: 80
    }
  },
  {
    path: '/conclusion',
    name: 'conclusion',
    component: () => import('../views/ConclusionPage.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      progress: 100
    },
  },
    {
    path: '/spots',
    name: 'spots',
    component: () => import('../views/ExampleSpotPage.vue'),
    meta: {
      layout: 'AppLayoutContainer',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
