<template>
  <v-footer bottom color="#EAF7FC">
    <v-row v-if="!atBegin" class="pa-4 pb-2 d-sm-none align-self-stretch">
      <v-col cols="10" offset="1" class="align-center">
        <progress-bar />
      </v-col>
    </v-row>
    <v-row class="space-between px-sm-2 my-0 align-self-stretch">
      <v-col cols="6" sm="4" class="d-flex align-center">
        <span v-if="atBegin" class="copyright align">
          &copy; {{ new Date().getFullYear() }} Sokal
        </span>
        <start-over v-else />
      </v-col>
      <v-col cols="4" class="d-none d-sm-flex align-center">
        <progress-bar />
      </v-col>
      <v-col cols="6" sm="4" md="3" offset-md="1" lg="2" offset-lg="2" class="d-flex flex-row justify-space-between pl-sm-6 pr-sm-2 pl-md-0 align-center" v-if="!atBegin && !atSpots">
        <nav-button nav="back" />
        <nav-button nav="next" />
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import StartOver from "./StartOverButton.vue";
import ProgressBar from "./ProgressBar.vue";
import NavButton from "./NavigationButton.vue";

export default {
  computed: {
    atBegin() {
      return this.$route.name === "home";
    },
    atEnd() {
      return this.$route.name === "conclusion";
    },
    atSpots() {
      return this.$route.name === "spots";
    },
    progress() {
      return this.$route.meta.progress;
    },
  },
  components: {
    StartOver,
    ProgressBar,
    NavButton
  },
};
</script>

<style>
footer {
  background: #EAF7FC;
  height: 80px;
}
footer .row {
  width: 100%;
}
.space-between {
  justify-content: space-between;
}
.copyright {
  color: #0E223F;
  display: block;
  font-size: 16px;
  float: left;
}

@media (max-width: 599px) {
  footer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: unset;
  }
  .space-between {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
