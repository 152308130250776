<template>
  <div class="start-over">
    <router-link class="r-flex" to="/">
      <div @click="defaultState">
        <img
        class="start-over-img"
        src="../assets/images/buttons/blue-chevron-right.svg"
        />
        <span class="footer-link back-btn">Back to home</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    nav: String,
  },
  computed: {},
  methods: {
    defaultState() {
      this.$store.dispatch("defaultState");
    },
  },
};
</script>

<style scoped>
.back-btn {
  text-decoration: none;
  color: #3F96B4;
  font-size: 24px;
}

.start-over-img {
  height: 24px;
  transform: rotate(180deg);
  float: left;
  margin-right: 25px;
  margin-top: 5px;
}

@media (max-width: 960px) {
  .back-btn {
    font-size: 18px;
  }
  .start-over-img {
    height: 18px;
    margin-right: 15px;
  }
}

@media (max-width: 599px) {
  .start-over-img {
    margin-right: 10px;
  }
}
</style>
