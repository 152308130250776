<template>
    <v-progress-linear
      color="#FF4438"
      background-color="rgba(63, 150, 180, .5)"
      height="3"
      v-if="showProgress"
      rounded
      :value="progress"
    >
    </v-progress-linear>
</template>

<script>

export default {
  computed: {
    showProgress() {
      if (this.$route.name !== "home" && this.$route.name !== "spots") {
        return true;
      } else {
        return false;
      }
    },
    progress() {
      return this.$route.meta.progress;
    },
  }
};
</script>

<style>
.v-progress-linear__background {
  max-height: 1px !important;
  top: 1px !important;
}
</style>
