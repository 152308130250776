<template>
  <v-app id="app">
    <v-main>
      <AppLayout>
        <router-view />
      </AppLayout>
    </v-main>
    
    <ca-footer />
  </v-app>
</template>

<script>
import AppLayout from "./layouts/AppLayout.vue";
import CaFooter from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    AppLayout,
    CaFooter
  },
};
</script>
<style>
#app {
  font-family: futura-pt, "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8A8A8D;
  background: #F8FDFF;
}
a {
  text-decoration: none !important;
}
</style>
