<template>
  <div>
    <router-link
      :class="nav"
      class="nav-button"
      :to="routeNavigation"
      :event="disableButton ? '' : 'click'"
    >
      <div class="d-flex align-center" v-if="showButton && navBack" :class="disableButton || atFirst ? 'disabled' : ''">
        <img class="nav-img previous" :src="buttonImage" />
        <span class="footer-link previous">{{ buttonText }}</span>
      </div>
      <div class="d-flex align-center" v-if="showButton && !navBack" :class="disableButton ? 'disabled' : ''">
        <span class="footer-link next">{{ buttonText }}</span>
        <img class="nav-img next" :src="buttonImage" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    nav: String,
  },
  data() {
    return {
      buttonImage: require("../assets/images/buttons/orange-chevron-right.svg"),
      backButton: "Prev",
      nextButton: "Next"
    };
  },
  computed: {
    navBack() {
      if (this.nav === "back") {
        return true;
      } else {
        return false;
      }
    },
    buttonText() {
      if (this.nav === "back") {
        return this.backButton;
      } else {
        return this.nextButton;
      }
    },
    atFirst() {
      return this.$route.name === 1;
    },
    routeNavigation() {
      if (this.$route.name === "conclusion" && this.nav === "back") {
        return "5";
      } else if (this.nav === "back" && this.$route.name === 1) {
        return "/";
      } else if (this.nav === "back") {
        return (this.$route.name - 1).toString();
      } else if (this.$route.name === 5) {
        return "conclusion";
      } else {
        return (this.$route.name + 1).toString();
      }
    },
    disableButton() {
      if (this.$route.meta.option) {
        return (
          this.$store.state[this.$route.meta.option].length < 1 &&
          this.nav === "next"
        );
      } else {
        return false;
      }
    },
    showButton() {
      if (this.nav === "back") {
        return true;
      } else if (this.nav === "next" && this.$route.name !== "conclusion") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.nav-img {
  height: 24px;
}
.footer-link {
  color: #FF4438;
  font-size: 24px;
  display: inline-block;
}
.footer-link.previous {
  margin-left: 25px;
}
.footer-link.next {
  margin-right: 25px;
}
.disabled {
  opacity: .5;
}
.nav-img.previous {
  transform: rotate(180deg);
}

@media (max-width: 960px) {
  .footer-link {
    font-size: 18px;
  }
  .nav-img {
    height: 18px;
  }
  .footer-link.previous {
    margin-left: 15px;
  }
  .footer-link.next {
    margin-right: 15px;
  }
}

@media (max-width: 599px) {
  .footer-link.previous {
    margin-left: 10px;
  }
  .footer-link.next {
    margin-right: 10px;
  }
}
</style>
